import React,{useState,useEffect} from 'react';
import styles from './exhi.module.css';
import bg from './assets/Exhi_back.jpeg';
import WorldMap from "./map";
import MapClick from "./m-map";
// import Footer from "../../components/Footer/Footer";
import OurReach from './ourreach';
import exhibitions from './assets/exhibitions.png'
// import cardlec from './assets/lectures_card1.1.png'
import card from './assets/Exhi_card.png'
import last1 from './assets/last1.jpg'
import last2 from './assets/last2.jpg'
import last3 from './assets/last3.png'
import last4 from './assets/last4.jpg'
import last5 from './assets/last5.png'
import last6 from './assets/last6.jpg'
import last7 from './assets/last7.jpg'
import last8 from './assets/last8.jpg'
import last9 from './assets/i1.jpg'
import last10 from './assets/last10.jpg'

const Exhi = () => {
    const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    // console.log(window.innerWidth)
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
    const bgHaiJi = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        // backgroundColor:'#e3e3ed',
        width: "100vw",
        position: "absolute",
        top: "0",
        zIndex: "-1",
        backgroundAttachment: "fixed",
    };

    return (
        <div>
            <div className={styles.exhibitions} style={bgHaiJi}>
            <div className={styles.heading}>
            <img src={exhibitions} style={{marginLeft:'10px',}}></img>
            </div>
                <div className={styles.exca}>
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img className = {styles.image} src={last2} alt="" />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>SOPHIA</h4>
                        <p className={styles.parainfo}>World's first humanoid robot to get citizenship</p>
                        </div> 
                        {/* <div style={{ position: 'absolute', top: '75%', left: '45%', zIndex: 2, color: 'black', backgroundColor: 'rgba(0, 0, 0, 0.5)',
                         padding: '1px', borderRadius: '5px', fontSize: '15px', color:'white', textAlign:'center', }}>
                        <p style={{fontWeight:'bold'}}>SOPHIA</p>
                        <p>Hong Kong</p>
                        </div> */}
                        {/* <div className={styles.info}>
                            <p className={styles.name}>SOPHIA</p>
                            <p>Hong Kong</p>
                        </div> */}
                        
                    </div>
                    
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img className= {styles.image} src={last7} alt="" />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>RoboThespian</h4>
                        <p className={styles.parainfo}>World's first actor and performer humanoid robot</p>
                        </div> 
                        {/* <div className={styles.cardcontent}>
                            <h3>Proto Hologram</h3>
                            <br />
                            <div className={styles.exhicard}>
                                USA <br /> Compact tabletop holographic communicator <br />
                            </div>
                            <p>The Proto M is your window into the Metaverse, from holoportation to recorded content. Connected with real-time customer experiences</p>
                        </div> */}
                        {/* <div className={styles.polyL}>
                            <p></p>
                        </div>
                        <div className={styles.polyR}>
                            <p></p>
                        </div> */}
                    </div>
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={last1} alt="" className={styles.image}/>
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>Gravity Industries</h4>
                        <p className={styles.parainfo}>The 𝗗𝗮𝗲𝗱𝗮𝗹𝘂𝘀, an exoskeleton that enables humans to fly!</p>
                        </div> 
                        {/* <div className={styles.cardcontent}>
                            <h3>Magnecko</h3>
                            <br />
                            <div className={styles.exhicard}>
                                Switzerland<br /> Magnetic climbing spider <br />
                            </div>
                            <p>A four-legged robot with magnetic feet, capable of doing inspection work on steel-made constructions in the industrial environment.</p>
                        </div> */}
                        {/* <div className={styles.polyL}>
                            <p></p>
                        </div>
                        <div className={styles.polyR}>
                            <p></p>
                        </div> */}
                    </div>
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={last9} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>Einstein Robot</h4>
                        <p className={styles.parainfo}>Humanoid robot that emulates Albert Einstein</p>
                        </div> 
                        {/* <div className={styles.cardcontent}>
                            <h3>Cito Libra</h3>
                            <br />
                            <div className={styles.exhicard}>
                                Switzerland <br /> Gyroscopically balanced vehicle <br />
                            </div>
                            <p>An electric motorcycle which efficiently balances itself by use of gyroscopes.</p>
                        </div> */}
                        {/* <div className={styles.polyL}>
                            <p></p>
                        </div>
                        <div className={styles.polyR}>
                            <p></p>
                        </div> */}
                    </div>
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={last10} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>Magnecko</h4>
                        <p className={styles.parainfo}>Magentic climbing spider</p>
                        </div> 
                        {/* <div className={styles.cardcontent}>
                            <h3>RDE</h3>
                            <br />
                            <div className={styles.exhicard}>
                                DRDO <br />
                            </div>
                            <p>RDE is engaged in the indigenous development of various engineering systems for all three wings of the Indian defense forces.</p>
                        </div> */}
                        {/* <div className={styles.polyL}>
                            <p></p>
                        </div>
                        <div className={styles.polyR}>
                            <p></p>
                        </div> */}
                    </div>
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={last3} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>TUM Hyperloop</h4>
                        <p className={styles.parainfo}>Four time SpaceX Hyperloop pod competition winner</p>
                        </div> 
                        {/* <div className={styles.cardcontent}>
                            <h3>Indian Army</h3>
                            <br />
                            <div className={styles.exhicard}>
                                Bofors 40 mm Automatic Gun L/70 Fwcs Radar <br /> Automatic anti-aircraft cannon
                            </div>
                        </div> */}
                        {/* <div className={styles.polyL}>
                            <p></p>
                        </div>
                        <div className={styles.polyR}>
                            <p></p>
                        </div> */}
                    </div>
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={last8} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>Proto Hologram</h4>
                        <p className={styles.parainfo}>Compact tabletop holographic communicator</p>
                        </div> 
                        {/* <div className={styles.cardcontent}>
                            <h3>Indian Army</h3>
                            <br />
                            <div className={styles.exhicard}>
                                Bofors 40 mm Automatic Gun L/70 Fwcs Radar <br /> Automatic anti-aircraft cannon
                            </div>
                        </div> */}
                        {/* <div className={styles.polyL}>
                            <p></p>
                        </div>
                        <div className={styles.polyR}>
                            <p></p>t
                        </div> */}
                    </div>
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={last4} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>AirCar Corp</h4>
                        <p className={styles.parainfo}>Autonomous, all-electric eVTOL</p>
                        </div> 
                        {/* <div className={styles.cardcontent}>
                            <h3>Indian Army</h3>
                            <br />
                            <div className={styles.exhicard}>
                                Bofors 40 mm Automatic Gun L/70 Fwcs Radar <br /> Automatic anti-aircraft cannon
                            </div>
                        </div> */}
                        {/* <div className={styles.polyL}>
                            <p></p>
                        </div>
                        <div className={styles.polyR}>
                            <p></p>
                        </div> */}
                    </div>
                    <div className={styles.card}>
                        <img src={card} className={styles.cardBackground} alt="Card Background"></img>
                        <img src={last6} alt="" className={styles.image} />
                        <div className={styles.text_exhi}>
                        <h4 className={styles.nameInfo}>NADINE</h4>
                        <p className={styles.parainfo}>Social humanoid robot</p>
                        </div> 
                        {/* <div className={styles.cardcontent}>
                            <h3>Indian Army</h3>
                            <br />
                            <div className={styles.exhicard}>
                                Bofors 40 mm Automatic Gun L/70 Fwcs Radar <br /> Automatic anti-aircraft cannon
                            </div>
                        </div> */}
                        {/* <div className={styles.polyL}>
                            <p></p>
                        </div>
                        <div className={styles.polyR}>
                            <p></p>
                        </div> */}
                    </div>
                </div>
            <div style={{marginTop:'150px'}}>{isMobile ? <MapClick /> : <WorldMap />}</div>
            <div style={{marginTop:'40px'}}><OurReach/></div>
            <div className={styles.Contactbox}>   
            <div className="Contactbox1">
              Tarun Kumar<br></br>
              tarun@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${9619678850}`)}>
                {7549952736}
              </div>
            </div>
            <div className="Contactbox1">
              Utkarsh Jain<br></br>
              utkarshjain@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${9619678850}`)}>
                {6264477005}
              </div>
            </div>
            <div className="Contactbox1">
              Aleem Rayeen<br></br>
              aleem@techfest.org<br></br>
              {/* <div onClick={() => window.location.href = `mailto:manas@techfest.org`}>{manas@techfest.org}</div><br></br> */}
              <div onClick={() => (window.location.href = `tel:${7317520804}`)}>
                {7068987053}
              </div>
            </div>
          
                    
                </div>
                
            </div>
            
            {/* <Footer/> */}
        </div>
    )
}

export default Exhi;