import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

// imgs
import fb from "./social media/fb1.png";
import insta from "./social media/insta1.png";
import linkedin from "./social media/linkedin1.png";
import pinterest from "./social media/pinterest1.png";
import x from "./social media/x1.png";
import yt from "./social media/yt1.png";
import bg from "./footimg.svg";
import fb1 from "./social media/facebook.png";
import insta1 from "./social media/instagram.png";
import yt1 from "./social media/youtube.png";
import x1 from "./social media/twitter.png";
import linkedin1 from './social media/linkedin.png';
import discord1 from './social media/discord1.png';
import discord from './social media/discord.png';


const Footer = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [showEvents, setShowEvents] = useState(false);

  const showMenu = () => {
    setOpenMenu(!openMenu);
  };

  const [showNavbar, setShowNavbar] = useState(true);
  const [prevScrollY, setPrevScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > prevScrollY) {
      // Scrolling down, hide the navbar
      setShowNavbar(false);
    } else {
      // Scrolling up, show the navbar
      setShowNavbar(true);
    }

    // Update the previous scroll position
    setPrevScrollY(currentScrollY);
    // console.log(showNavbar);
  };

  useEffect(() => {
    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  return (
    <div>
      <div
        className={`footfunc footWeb ${
          showNavbar ? "footfuncshow" : "footfunchide"
        }`}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="footLinkO footLink">
          {/* <div className="footMulti">
            <li>
              <Link to="/history">History</Link>
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
          </div>
            <li>
              <Link to="/recognition">Recognition</Link>
            </li>
          <div className="footMulti">
            <li>
              <Link to="/aboutus/media">Media</Link>
            </li>
            <li>
              <Link to="/legals">Legals</Link>
            </li>
          </div> */}
          <li>
            <Link to="/contactus">Contact Us</Link>
          </li>
          {/* <li>
            <Link to="/sponsors">Sponsors</Link>
          </li> */}
          <li onMouseLeave={() => setShowEvents(false)}>
            <Link onMouseEnter={() => setShowEvents(true)}>About Us</Link>

            <div
              className={`about ${showEvents ? "aboutyo" : "aboutno"}`}
              onMouseEnter={() => setShowEvents(true)}
              onMouseLeave={() => setShowEvents(false)}
            >
              <li>
                <Link to="/aboutus/history">History</Link>
              </li>
              {/* <li>
                <Link to="/aboutus/gallery">Gallery</Link>
              </li> */}
              <li>
                <Link to="/aboutus/media">Media</Link>
              </li>
              <li>
                <Link to="/aboutus/legals">Legals</Link>
              </li>
              <li>
                <Link to="/aboutus/recognition">Recognition</Link>
              </li>
            </div>
          </li>
        </div>
         <div className="anounceIt">
          <Link to="/workshops">Workshops are Live Now!</Link>
          <br /> 
          <br /> 
          <a href="/accommodation"></a> 
           <Link to='/accommodation'>Stay at IIT Bombay</Link>
          <br />
          
        </div> 
 <div className="socialMedia">
  <li>
    <a href="https://www.instagram.com/techfest_iitbombay/?hl=en" target="_blank">
      <img src={insta} className="insta-img default" alt="Instagram" />
      <img src={insta1} className="insta-img hover" alt="Instagram" />
    </a>
  </li>
  <li>
    <a href="https://www.facebook.com/iitbombaytechfest/" target="_blank">
      <img src={fb} className="fb-img default" alt="Facebook" />
      <img src={fb1} className="fb-img hover" alt="Facebook" />
    </a>
  </li>
  <li>
    <a href="https://twitter.com/Techfest_IITB" target="_blank">
      <img src={x} className="x-img default" alt="Twitter" />
      <img src={x1} className="x-img hover" alt="Twitter" />
    </a>
  </li>
  <li>
    <a href="https://www.youtube.com/user/techfestiitbombay" target="_blank">
      <img src={yt} className="yt-img default" alt="YouTube" />
      <img src={yt1} className="yt-img hover" alt="YouTube" />
    </a>
  </li>
  <li>
    <a href="https://in.linkedin.com/company/techfest" target="_blank">
      <img src={linkedin} className="linkedin-img default" alt="LinkedIn" />
      <img src={linkedin1} className="linkedin-img hover" alt="LinkedIn" />
    </a>
  </li>
  <li>
    <a href="https://discord.com/invite/fVD8gZ6r" target="_blank">
      <img src={discord} className="discord-img default" alt="Discord" />
      <img src={discord1} className="discord-img hover" alt="Discord" />
    </a>
  </li>
 </div>

      </div>
    </div>
  );
};

export default Footer;
